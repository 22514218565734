import React, { Component } from "react";

class Pig extends Component {
  render() {
    return (
      <svg
        className="money-pig"
        id="pig"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 387.49 257.54"
      >
        <defs />
        <g id="Pig-2" data-name="Pig">
          <path
            className="cls-1"
            d="M201.12,161.23v-8.35c-5.88-.26-11.59-1.84-14.93-3.77l2.64-10.28a31,31,0,0,0,14.58,3.87c5,0,8.43-1.94,8.43-5.45,0-3.34-2.81-5.44-9.31-7.64-9.4-3.16-15.81-7.55-15.81-16.07,0-7.73,5.44-13.79,14.84-15.64V89.56h8.61v7.73a31.43,31.43,0,0,1,12.73,2.9l-2.54,9.92a29.29,29.29,0,0,0-12.65-3c-5.71,0-7.55,2.46-7.55,4.92,0,2.89,3.07,4.74,10.54,7.55,10.45,3.69,14.66,8.52,14.66,16.42s-5.53,14.49-15.63,16.25v9Z"
            id="pigdollar"
          />
          <path
            className="cls-2"
            d="M240.15,45.7a4.36,4.36,0,0,0,.82-8.65,193.44,193.44,0,0,0-36.64-3.45,257.38,257.38,0,0,0-33.66,2.13,4.36,4.36,0,1,0,1.15,8.65,248.53,248.53,0,0,1,32.51-2.05,184.15,184.15,0,0,1,35,3.29A4.87,4.87,0,0,0,240.15,45.7Z"
            id="pigslot"
          />
          <path
            id="pig_eye"
            data-name="pig eye"
            className="cls-2"
            d="M296.22,94.24A8.81,8.81,0,1,0,305,85.43,8.82,8.82,0,0,0,296.22,94.24Z"
          />
          <path
            id="pigshap"
            className="cls-2"
            d="M50.64,126.38c0,34.64,16.92,65.62,47.7,87.49l4.28,34.94a10.08,10.08,0,0,0,9.87,8.73h42.13a9.72,9.72,0,0,0,9.42-9.78l0-6.91a223.66,223.66,0,0,0,42.51,4,191.14,191.14,0,0,0,40.26-4.68v7.77a9.47,9.47,0,0,0,9.44,9.46l42.1.08a10.08,10.08,0,0,0,9.89-8.79l3.93-33.4c17.36-9.85,39.05-25.47,50.61-46.55l14.95-1.79a11.26,11.26,0,0,0,9.81-11v-47a11.28,11.28,0,0,0-9.81-11.06l-18.5-2.21c-10-19.17-26.32-36.17-47.45-49.43a145.46,145.46,0,0,1,6.68-36.65,5.72,5.72,0,0,0,.3-1.64A7.73,7.73,0,0,0,316.64,2a6.64,6.64,0,0,0-4.8-2,8.06,8.06,0,0,0-5,1.83L273,28A209.89,209.89,0,0,0,206.51,17.5c-84.6,0-143.68,34.72-154.19,90.61-.22,1.32-.4,2.62-.57,3.89C51.75,112,50.64,126.06,50.64,126.38Zm10.91,0A128.15,128.15,0,0,1,63.06,110c9.46-50.27,64.43-81.58,143.45-81.58A198.41,198.41,0,0,1,272.25,39.3a5.46,5.46,0,0,0,5.14-.83l27.55-21.34A153.94,153.94,0,0,0,300.72,49a5.46,5.46,0,0,0,2.64,4.79c21.65,13,38,30,47.36,49.22a5.44,5.44,0,0,0,4.26,3l21.41,2.55a.66.66,0,0,1,.2.27v47a.71.71,0,0,1-.25.23l-17.77,2.12a5.47,5.47,0,0,0-4.24,3c-8.1,16.33-25.83,32.61-49.93,45.87a5.46,5.46,0,0,0-2.79,4.14l-4.15,35.31-39.79-.06,0-13.21a5.5,5.5,0,0,0-2.1-4.31,5.44,5.44,0,0,0-4.69-1,186,186,0,0,1-44.4,5.95,209.5,209.5,0,0,1-46.79-5.12,5.62,5.62,0,0,0-1.22-.13,5.43,5.43,0,0,0-5.46,5.49l.08,12.48H113.34l-4.47-36.51a5.44,5.44,0,0,0-2.34-3.84C77.53,186.44,61.55,158.07,61.55,126.38Z"
          />
        </g>
        <path
          id="pigtail"
          className="cls-2"
          d="M43.1,117c-2.31.92-5.18,2.06-9.18,4a29.06,29.06,0,0,0-11.12,9.22,39.83,39.83,0,0,0-9.27,2.16,52.57,52.57,0,0,0-11.26,5.29,4.91,4.91,0,0,0,5.28,8.28,42.77,42.77,0,0,1,9.19-4.29c.54-.19,1.07-.36,1.58-.52a19.39,19.39,0,0,0,6,17.28l.16.15a14,14,0,0,0,9.15,3.55,12.35,12.35,0,0,0,8-3c4.23-3.55,6.43-10.56,1.84-18.19a24,24,0,0,0-9.19-8.74,19.4,19.4,0,0,1,3.77-2.35c3.73-1.76,6.44-2.84,8.61-3.7,1.49-.59,2.77-1.12,4-1.67l1.07-12.73A41.07,41.07,0,0,1,43.1,117Zm-7.9,29.12c1.35,2.26,1.45,4.41.16,5.49-.88.74-2.55,1.06-4.37-.46a9.81,9.81,0,0,1-2.92-8.91,13,13,0,0,1,.45-1.89C31.13,141.18,33.23,143.07,35.2,146.12Z"
        />
      </svg>
    );
  }
}

export default Pig;
